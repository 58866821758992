import { StoreAction, PlanningState } from '../../utils/types/storeTypes';
import {
    TOGGLE_DISPLAY_WORKING_TIME, CHANGE_SETTINGS, CHANGE_TEMPLATES, CHANGE_DRAGGED_GROUP_EVENT_USER_ID,
    CHANGE_DRAGGED_USER_EVENT_USER_ID, SET_EVENT_METHODS, CHANGE_CELLS_PER_ROW, RESET, CHANGE_USER_ROWS, CHANGE_SELECT_GROUPS, CHANGE_SELECT_USERS, TOGGLE_LOADING_PLANNING
} from '../reducer/planning';
import { PlanningSettings, PlanningTemplate, PlanningEvent, PlanningOvertime, PlanningUserRow } from '../../utils/types/planningTypes';
import { ThunkAction } from 'redux-thunk';

export type Effect = ThunkAction<any, PlanningState, any, StoreAction>;

/**
 * Toggle stored displayWorkingTime state
 */
 export const toggleLoadingPlanning = (l: boolean): StoreAction => {
    return { type: TOGGLE_LOADING_PLANNING, data: l };
}

/**
 * Toggle stored displayWorkingTime state
 */
 export const changeUserRows = (userRows: PlanningUserRow[]): StoreAction => {
    return { type: CHANGE_USER_ROWS, data: userRows };
}

/**
 * Toggle stored displayWorkingTime state
 */
 export const changeSelectGroups = (selectGroups?: number[]): StoreAction => {
    return { type: CHANGE_SELECT_GROUPS, data: selectGroups };
}

/**
 * Toggle stored displayWorkingTime state
 */
 export const changeSelectUsers = (selectUsers?: number[]): StoreAction => {
    return { type: CHANGE_SELECT_USERS, data: selectUsers };
}

/**
 * Toggle stored displayWorkingTime state
 */
export const toggleDisplayWorkingTime = (): StoreAction => {
    return { type: TOGGLE_DISPLAY_WORKING_TIME };
}

/**
 * Change stored templates
 * @param templates the new templates
 */
export const changeTemplates = (templates: PlanningTemplate[]): StoreAction => {
    return { type: CHANGE_TEMPLATES, data: templates };
}

/**
 * Change stored settings
 * @param settings the new settings
 */
export const changeSettings = (settings: PlanningSettings): StoreAction => {
    return { type: CHANGE_SETTINGS, data: settings };
}

/**
 * Change stored currentWeek
 * @param currentWeek the new currentWeek
 */
export const changeCellsPerRow = (cellsPerRow: number): StoreAction => {
    return { type: CHANGE_CELLS_PER_ROW, data: cellsPerRow };
}

/**
 * Change the stored dragged group event user's id
 * @param id the user's id
 */
export const changeDraggedGroupEventUserId = (id: number | undefined): StoreAction => ({ type: CHANGE_DRAGGED_GROUP_EVENT_USER_ID, data: id });


/**
 * Change the stored dragged user event user's id
 * @param id the user's id
 */
export const changeDraggedUserEventUserId = (id: number | undefined): StoreAction => ({ type: CHANGE_DRAGGED_USER_EVENT_USER_ID, data: id });

/**
 * Set the event methods
 * @param onClickEvent method
 * @param onEditEvent method
 * @param onDeleteEvent method
 */
export const setEventMethods = (
    onClickEvent: (event: PlanningEvent) => void,
    onEditEvent: (event: PlanningEvent) => void,
    onDeleteEvent: (event: PlanningEvent) => void,
    onEditOvertime: (o: PlanningOvertime) => void,
    onDeleteOvertime: (o: PlanningOvertime) => void): StoreAction => ({type: SET_EVENT_METHODS, data: {onClickEvent, onEditEvent, onDeleteEvent, onEditOvertime, onDeleteOvertime}});

/**
 * Reset planning reducer
 */
export const reset = () => ({ type: RESET });