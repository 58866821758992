import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { Avatar, Button, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { LoginUserSmall } from "../../../utils/types/generalTypes";
import CustomIcon from "../../common/general/customIcon";

interface Props {
    users: LoginUserSmall[];
    setUser: (user: LoginUserSmall | undefined) => void;
    setUsers: (users: LoginUserSmall[]) => void;
}

const LoginMutlipleView = (props: Props) => {
    return (
        <>
            <Col xs={{ span: 24 }} style={{ display: 'flex' }}>
                <span style={{ margin: 'auto', fontWeight: 'bold', fontSize: 20 }}><FormattedMessage defaultMessage={'Please select your account'} /></span>
            </Col>
            {
                props.users.map((user, index) => {
                    return (
                        <Col xs={{ span: 12 }} key={index}>
                            <div className='login-multiple-user' onClick={() => {
                                props.setUser(user);
                            }}>
                                {/* <span style={{ margin: 'auto', fontWeight: 'bold', fontSize: 20 }}>{user.companyName}</span> */}
                                <Avatar style={{ margin: '10px auto', width: 100, height: 100 }} src={user.image} icon={<CustomIcon icon={faUser} iconSize={60} />} />
                                {/* <span style={{ margin: 'auto', fontWeight: 'bold' }}>{`${user.firstName} ${user.lastName}`}</span> */}
                            </div>
                        </Col>
                    );
                })
            }
            <Col xs={{ span: 24 }} style={{ display: 'flex' }}>
                <Button
                    style={{ margin: 'auto' }}
                    onClick={() => props.setUsers([])}
                >
                    <FormattedMessage defaultMessage={'Back'} />
                </Button>
            </Col>
        </>
    );
};

export default LoginMutlipleView;