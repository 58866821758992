import Cookie from 'js-cookie';
import request from 'superagent';

// const Compress = require('compress.js').default
export const AUTH_FAILED = 'AUTH_FAILED';
const INTERNET_ERROR_MESSAGE = 'Not able to connect. Please check your internet connection.';
const API_ERROR_MESSAGE = 'Could not load data. Please try again.';

let BASE_URL = process.env.REACT_APP_BASE_URL;
if (!BASE_URL) {
  //BASE_URL = "https://preprod.api.barry-events.ch/api/";
  BASE_URL = "https://api.barry-events.ch/api/";
  //BASE_URL = 'http://localhost:8000/api/';
}

const baseResponseHandler = (err, res, reject, successCallBack) => {
  if (err) {
    if (err.status === 401) {
      Cookie.remove('userId');
      reject({ message: AUTH_FAILED });
      return;
    } else if (err.status === 502) {
      reject({ message: INTERNET_ERROR_MESSAGE });
      return;
    } else if (err.status === 400) {
      reject({ message: res.text });
      return;
    } else {
      reject({ message: API_ERROR_MESSAGE });
      return;
    }
  }
  successCallBack();
};

export const apiRequest = (url_subpart, requestData) => {
  return new Promise((resolve, reject) => {
    request.post(BASE_URL + url_subpart)
      .send(requestData)
      .set("Authorization", Cookie.get('userId'))
      .set("X-CLIENT-TYPE", 'ADMIN')
      .end(function (err, res) {
        baseResponseHandler(err, res, reject, () => {
          resolve(res.body);
        });
      });
  });
};

// export const compressedImageUpload = (imageFile, url, callback) => {
//   const compress = new Compress();
//   compress.compress([imageFile], {
//     size: 2, // the max size in MB, defaults to 2MB
//     quality: .75, // the quality of the image, max is 1,
//     maxWidth: 800, // the max width of the output image, defaults to 1920px
//     maxHeight: 800, // the max height of the output image, defaults to 1920px
//     resize: true, // defaults to true, set false if you do not want to resize the image width and height
//   }).then((data) => {
//     const img1 = data[0];
//     const base64str = img1.data;
//     const imgExt = img1.ext;
//     const file = Compress.convertBase64ToFile(base64str, imgExt);

//     const reader = new FileReader();
//     reader.onload = (e) => {
//       apiRequestFileUpload(url, e.target.result).then(
//         response => {
//           callback(true, response);
//         },
//         error => {
//           callback(false, error);
//         }
//       );
//     };

//     reader.readAsArrayBuffer(file);
//   });
// };

// export const imageUpload = (imageFile, url, callback) => {
//   const reader = new FileReader();
//     reader.onload = (e) => {
//       apiRequestFileUpload(url, e.target.result).then(
//         response => {
//           callback(true, response);
//         },
//         error => {
//           callback(false, error);
//         }
//       );
//     };
//     reader.readAsArrayBuffer(imageFile);
// };

// export const apiRequestFileUpload = (url, fileData) => {
//   return new Promise((resolve, reject) => {

//     var oReq = new XMLHttpRequest();
//     oReq.open("PUT", url, true);
//     oReq.setRequestHeader("Content-Type", "application/octet-stream");
//     oReq.onload = function (oEvent) {
//       // Uploaded.
//       console.log(fileData)
//       resolve(oEvent);
//     };
//     oReq.onError = function (error) {
//       reject({ message: API_ERROR_MESSAGE });
//     };

//     let blob = new Uint8Array(fileData);

//     oReq.send(blob);
//   });
// };


export const apiRequestGet = (url_subpart) => {
  return new Promise((resolve, reject) => {
    request.get(BASE_URL + url_subpart)
      .set("Authorization", Cookie.get('userId'))
      .set("X-CLIENT-TYPE", 'ADMIN')
      .end(function (err, res) {
        baseResponseHandler(err, res, reject, () => {
          resolve(res.body);
        });
      });
  });
};

export const getRequest = (url_subpart) => {
  return new Promise((resolve, reject) => {
    request.get(url_subpart)
      .end(function (err, res) {
        baseResponseHandler(err, res, reject, () => {
          resolve(res.body);
        });
      });
  });
};

export const apiRequestDelete = (url_subpart) => {
  return new Promise((resolve, reject) => {
    request.delete(BASE_URL + url_subpart)
      .set("Authorization", Cookie.get('userId'))
      .set("X-CLIENT-TYPE", 'ADMIN')
      .end(function (err, res) {
        baseResponseHandler(err, res, reject, () => {
          resolve(res.body);
        });
      });
  });
};

export const apiRequestFile = (url_subpart, requestData) => {
  return new Promise((resolve, reject) => {
    request.post(BASE_URL + url_subpart)
      .send(requestData)
      .set("Authorization", Cookie.get('userId'))
      .set("X-CLIENT-TYPE", "ADMIN")
      .responseType('blob')
      .end((err, res) => {
        baseResponseHandler(err, res, reject, () => {
          resolve(res.body);
        });
      })
  })
}

export const login = (userId, password, rememberMe) => {
  return new Promise((resolve, reject) => {
    request.post(BASE_URL + 'user/login/user')
      .send({ userId, password })
      .end(function (err, res) {
        baseResponseHandler(err, res, reject, () => {
          resolve(res.body);
          if (rememberMe) {
            Cookie.set('userId', res.body.token);
          } else {
            Cookie.set('userId', res.body.token);
          }
          // localStorage.setItem('userId', res.body.token);
        });
      });
  });
};

export const loginPasswordSet = (userId, code, password) => {
  return new Promise((resolve, reject) => {
    request.post(BASE_URL + 'user/login/mobile/password')
      .send({ userId, code, password})
      .end(function (err, res) {
        baseResponseHandler(err, res, reject, () => {
          resolve(res.body);
          Cookie.set('userId', res.body.token);
          // localStorage.setItem('userId', res.body.token);
        });
      });
  });
};


export const logout = () => {
  return new Promise((resolve, reject) => {
    request.get(BASE_URL + 'user/logout')
      .end(function (err, res) {
        baseResponseHandler(err, res, reject, () => {
          resolve(res.body);
        });
      });
  });
}