import { Button, Col } from "antd";
import Password from "antd/lib/input/Password";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { changeUserDetails } from "../../../store/actions/user";
import Network from "../../../utils/network";
import { LoginUserSmall } from "../../../utils/types/generalTypes";
import { showNotification } from "../../../utils/utils";

interface Props {
    user: LoginUserSmall;
    setUser: (user: LoginUserSmall | undefined) => void;
    setForgotPassword: (forgotPassword: boolean) => void;
}

const LoginNormalView = (props: Props) => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { lang } = useParams();

    const [password, setPassword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const loginUser = () => {
        setLoading(true);
        Network.login(props.user.id, password, true).then(
            response => {
                dispatch(changeUserDetails(response));
                navigate(`/${lang}/dashboard`);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'Wrong credentials' }), 'error');
                setLoading(false);
            }
        );
    };

    return (
        <>
            <Col xs={{ span: 24 }} style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ margin: '0px auto 10px auto', fontSize: 18, fontWeight: 'bold' }}>
                    <FormattedMessage defaultMessage={'Login'} />
                </p>
            </Col>
            <Col xs={{ span: 24 }} style={{ display: 'flex', flexDirection: 'column' }}>
                <Password
                    placeholder={intl.formatMessage({ defaultMessage: 'Password' })}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onPressEnter={loginUser}
                    autoFocus
                />
                <p
                    className='login-forgot'
                    onClick={() => props.setForgotPassword(true)}>
                    <FormattedMessage defaultMessage={'Forgot your password?'} />
                </p>
            </Col>
            <Col xs={{ span: 24 }} style={{ display: 'flex' }}>
                <Button
                    style={{ margin: 'auto' }}
                    disabled={loading}
                    onClick={() => props.setUser(undefined)}>
                    <FormattedMessage defaultMessage={'To login page'} />
                </Button>
                <Button
                    style={{ margin: 'auto' }}
                    type='primary'
                    loading={loading}
                    disabled={password === ''}
                    onClick={loginUser}>
                    <FormattedMessage defaultMessage={'Login'} />
                </Button>
            </Col ></>
    );
};

export default LoginNormalView;