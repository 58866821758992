import { StoreAction, WindowState } from '../../utils/types/storeTypes';

export const WINDOW_INIT_STATE: WindowState = {
    width: 0, //the current width of the window
    height: 0, //the current height of the window
    isSmartphone: false, //if the window is of smartphone size (width <= 900)
    navigationCollapsed: false, //if the side navigation bar is collapsed
    language: 'fr'
}

export const TYPE_WINDOW_WIDTH = Symbol('WINDOW_WIDTH');
export const TYPE_WINDOW_HEIGHT = Symbol('WINDOW_HEIGHT');
export const TYPE_WINDOW_NAVIGATION_COLLAPSED = Symbol('WINDOW_NAVIGATION_COLLAPSED');
export const CHANGE_LANGUAGE = Symbol('CHANGE_LANGUAGE')
export const RESET = Symbol('RESET')


const window = (state: WindowState = WINDOW_INIT_STATE, action: StoreAction) => {
    switch(action.type) {
        case TYPE_WINDOW_WIDTH: 
            return {
                ...state,
                width: action.data,
                isSmartphone: action.data <= 900,
            };
        case TYPE_WINDOW_HEIGHT:
            return {
                ...state,
                height: action.data,
            };
        case TYPE_WINDOW_NAVIGATION_COLLAPSED:
            return {
                ...state,
                navigationCollapsed: !state.navigationCollapsed,
            };
        case CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.data,
            };
        case RESET:
            return WINDOW_INIT_STATE;
        default:
           return state;
    }
}

export default window;