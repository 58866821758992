import { Alert, Button, Space, message, notification } from 'antd';
import ReactDOM from 'react-dom';

import { IntlShape } from 'react-intl';
import { RGB } from './types/generalTypes';



// const momentDurationFormatSetup = require('moment-duration-format');
// momentDurationFormatSetup(moment);

/* General */
export const emptyCache = () => {
    if ('caches' in window) {
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload();
    }
}
/**
 * Create an alert
 * @param message the message to display
 * @param type the type of the message: "success" | "info" | "warning" | "error" | undefined
 * @param showIcon if the icon must be visible
 * @param description an additional text
 */
export const alert = (message: string, type: "success" | "info" | "warning" | "error" | undefined, showIcon: boolean = true, description?: string) => {
    //get container by id
    const container = document.getElementById("container");

    //delete previous alert
    const previousAlert = document.getElementById('alert-container');
    if (previousAlert) container?.removeChild(previousAlert);

    //add div
    let div = document.createElement("div");
    div.className = "container-alert";
    div.id = "alert-container";
    container?.appendChild(div);

    //create alert
    const component = <Alert
        message={message}
        type={type}
        showIcon={showIcon}
        description={description} />

    // render component
    const alert = document.getElementById('alert-container');
    if (alert) ReactDOM.render(component, alert);
}

export const showNotification = (title: string, type: string, description?: string, intl?: IntlShape) => {
    switch (type) {
        case 'success':
            notification.success({
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                bottom: 0,
                placement: 'bottomRight',
            })
            break;
        case 'error':
            notification.error({
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                bottom: 0,
                placement: 'bottomRight',
            })
            break;
        case 'info':
            notification.info({
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                bottom: 0,
                placement: 'bottomRight',
            })
            break;
        case 'warn':
            notification.warn({
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                bottom: 0,
                placement: 'bottomRight',
            })
            break;
        case 'warning':
            notification.warning({
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                bottom: 0,
                placement: 'bottomRight',
            })
            break;
        case 'update':
            const btn = (
                <Space>
                    <Button type="default" onClick={() => emptyCache()}>
                        {intl ? intl.formatMessage({ defaultMessage: 'Update', description: 'notification' }) : 'intlShape missing'}
                    </Button>
                    <Button type="primary" onClick={() => notification.close('update-notif-key')}>
                        {intl ? intl.formatMessage({ defaultMessage: 'Close', description: 'notification' }) : 'intlShape missing'}
                    </Button>
                </Space>
            );
            notification.info({
                duration: 0,
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                //bottom: 0,
                placement: 'topRight',
                key: 'update-notif-key',
                btn

            })
            break;
        default:
            notification.info({
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                bottom: 0,
                placement: 'bottomRight',
            })
            break;
    }
};

/**
 * Display an error message
 * @param msg the message to display
 * @param shakeId the id of the component to shake
 */
export const displayErrorMessage = (msg: string, shakeId?: string, timeToShow?: number): void => {
    const component = shakeId ? document.getElementById(shakeId) : undefined;
    if (component && !component.className.includes("shake-animation")) {
        component.className += " shake-animation";
        setTimeout(() => component.className = component.className.replace(" shake-animation", ""), 850);
    }
    const time = timeToShow ? timeToShow : 6
    message.error(msg, time);
}

/**
 * Convert a hexadecimal color to rgb color
 * @param hex the hexadecimal color
 * @param amt an value to add to each rgb value - optional
 */
export const hexToRgb = (hex: string, amt = 0): RGB => {
    const num = parseInt(hex.replace("#", ""), 16);
    return {
        red: (num >> 16) + amt,
        green: (num & 0x0000FF) + amt,
        blue: ((num >> 8) & 0x00FF) + amt,
    };
}

/**
 * Convert rgb color to hexadecimal color
 * @param red the red value
 * @param green the green value
 * @param blue the blue value
 * @returns the hexadecimal color
 */
export function rgbToHex(red: number, green: number, blue: number): string {
    return '#' + (0x1000000 + (red < 255 ? red < 1 ? 0 : red : 255) * 0x10000 + (blue < 255 ? blue < 1 ? 0 : blue : 255) * 0x100 + (green < 255 ? green < 1 ? 0 : green : 255)).toString(16).slice(1);
}

/**
 * Get a darker version of a color
 * @param color the base color in hexadecimal
 * @param percent the percent effect  - optional
 * @return the resulted color in hexadecimal
 */
export const darker = (color: string, percent = 20): string => {
    const num = parseInt(color.replace("#", ""), 16);
    const amt = Math.round(2.55 * percent);
    const red = (num >> 16) - amt, blue = ((num >> 8) & 0x00FF) - amt, green = (num & 0x0000FF) - amt;
    return '#' + (0x1000000 + (red < 255 ? red < 1 ? 0 : red : 255) * 0x10000 + (blue < 255 ? blue < 1 ? 0 : blue : 255) * 0x100 + (green < 255 ? green < 1 ? 0 : green : 255)).toString(16).slice(1);
}

/**
 * Get a lighter version of a color
 * @param color the base color in hexadecimal
 * @param percent the percent effect - optional
 * @return the resulted color in hexadecimal
 */
export const lighter = (color: string, percent = 20): string => {
    const amt = Math.round(2.55 * percent);
    const rgb = hexToRgb(color, amt);
    return rgbToHex(rgb.red, rgb.green, rgb.blue);
}

/**
 * Check if a color is light
 * @param color the color to check
 * @returns true if the color is light, false otherwise
 */
export const colorIsBright = (color: string): boolean => {
    let rgb = hexToRgb(color);
    rgb.red /= 255;
    rgb.green /= 255;
    rgb.blue /= 255;

    return Math.round((Math.max(rgb.red, rgb.green, rgb.blue) + Math.min(rgb.red, rgb.green, rgb.blue)) / 2) >= 0.5;
}


/**
 * Desaturate a color
 * @param color the base color in hexadecimal
 * @param percent the percent effect - optional
 */
export const desaturate = (color: string, percent = 20): string => {
    const rgb = hexToRgb(color);

    const saturation = percent / 100;
    const gray = rgb.red * 0.3086 + rgb.green * 0.6094 + rgb.blue * 0.0820;

    const red = Math.round(rgb.red * saturation + gray * (1 - saturation));
    const green = Math.round(rgb.green * saturation + gray * (1 - saturation));
    const blue = Math.round(rgb.blue * saturation + gray * (1 - saturation));
    return rgbToHex(red, green, blue);
}

/**
 * permet de calculer la distance entre 2 points de coordonées
 * @param coord1: {lat: xxx, lon: xxx}
 * @param coord2 : {lat: xxx, lon: xxx}
 * @returns distance between 2 coordiante points
 */
export const haversine = (coord1: { lon: number, lat: number }, coord2: { lon: number, lat: number }) => {
    let lonA = coord1.lon
    let latA = coord1.lat
    let lonB = coord2.lon
    let latB = coord2.lat
    const { PI, sin, cos, atan2 } = Math,
        r = PI / 180,
        R = 6371,
        deltaLat = (latB - latA) * r,
        deltaLon = (lonB - lonA) * r,
        a = sin(deltaLat / 2) ** 2 + cos(cos(latB * r) * latA * r) * sin(deltaLon / 2) ** 2,
        c = 2 * atan2(a ** 0.5, (1 - a) ** 0.5),
        d = R * c
    return d
}