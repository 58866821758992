import { cloneDeep } from 'lodash';
import { StoreAction, EventsState } from '../../utils/types/storeTypes';

export const PLANNING_INIT_STATE: EventsState = {
    missions: undefined,
    loadingMissions: false,
    events: undefined,
    loadingEvents: false
};

export const CHANGE_MISSIONS = Symbol('CHANGE_MISSIONS');
export const CHANGE_LOADING_MISSIONS = Symbol('CHANGE_LOADING_MISSIONS');
export const CHANGE_EVENTS = Symbol('CHANGE_EVENTS');
export const CHANGE_LOADING_EVENTS = Symbol('CHANGE_LOADING_EVENTS');
export const RESET = Symbol('RESET');

const planning = (state: EventsState = PLANNING_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case CHANGE_MISSIONS:
            return {
                ...state,
                loadingMissions: false,
                missions: cloneDeep(action.data),
            }
        case CHANGE_LOADING_MISSIONS:
            return {
                ...state,
                loadingMissions: action.data,
            }
        case CHANGE_EVENTS:
            return {
                ...state,
                loadingEvents: false,
                events: cloneDeep(action.data),
            }
        case CHANGE_LOADING_EVENTS:
            return {
                ...state,
                loadingEvents: action.data,
            }
        case RESET:
            return PLANNING_INIT_STATE;
        default:
            return state;
    }
};

export default planning;