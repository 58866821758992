import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { Spin } from "antd";
import '../../styles/loading.css';
import CustomIcon from "../common/general/customIcon";

const LoadingComponent = () => {




    return (
        <div className='loading-spinner-container' >
            <Spin className='loading-spinner' size='large' indicator={<CustomIcon icon={faSpinnerThird} duoTone="#00B0EF" iconSize={100} spin />} />
        </div>
    );
}
export default LoadingComponent