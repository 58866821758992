import { useCallback, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useParams } from "react-router-dom";
import de from './compiled-locale/de.json';
import en from './compiled-locale/en.json';
import fr from './compiled-locale/fr.json';
import it from './compiled-locale/it.json';

interface Props {
    children?: React.ReactNode;
}

const LanguageProvider = (props: Props) => {

    const [language, setLanguage] = useState<{ local: string, messages: any; }>({ local: 'fr', messages: fr });
    const { lang } = useParams();

    const getLanguage = useCallback(() => {
        switch (lang) {
            case 'fr':
                return { local: 'fr', messages: fr };
            case 'de':
                return { local: 'de', messages: de };
            case 'it':
                return { local: 'it', messages: it };
            case 'en':
            default:
                return { local: 'en', messages: en };
        }
    }, [lang]);

    useEffect(() => {
        setLanguage(getLanguage);
    }, [getLanguage]);

    return (
        <IntlProvider locale={language.local} messages={language.messages}>
            {props.children}
        </IntlProvider>
    );
};

export default LanguageProvider;