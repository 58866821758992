import { Dropdown, Menu } from "antd";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { AvailableLanguages } from "../../../utils/constants";

interface Props {
    lang?: string
    navigateTo: string
    placement?: "bottom" | "topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter" | "bottomRight" | "top"
}

const LanguageMenu = (props: Props) => {

    const navigate = useNavigate()
    const { lang } = useParams()

    const LanguageFlags: { [key: string]: string; } = {
        'en': 'GB',
        'fr': 'FR',
        'de': 'DE',
        'it': 'IT',
    };

    const menu = () => {
        return (
            <Menu>
                {
                    AvailableLanguages.map((item) => (
                        <Menu.Item key={item.value} onClick={e => {
                            navigate(`/${item.value}/${props.navigateTo}`, { replace: true })
                            moment.locale(item.value)
                        }}>
                            <div className={props.lang === item.value ? 'language-selected' : ''} style={{ display: 'flex' }}>
                                {getLangImage(item.value)}
                                <span style={{ margin: 'auto auto auto 10px' }}>{item.label}</span>
                            </div>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }


    const getLangImage = (lang?: string) => (<div >
        <img src={`https://flagsapi.com/${LanguageFlags[lang ?? 'fr']}/flat/32.png`} alt={`flag-lang-${lang}`} />
    </div>);

    return (
        <Dropdown overlay={menu} placement={props.placement ? props.placement : 'bottom'}>
            <span onClick={e => {
                e.preventDefault();
            }} style={{ float: 'right', marginRight: 20, marginLeft: 10 }}>
                {getLangImage(lang)}
            </span>
        </Dropdown>
    )
}

export default LanguageMenu;