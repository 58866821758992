import { StoreAction, PlanningState } from '../../utils/types/storeTypes';
import {
    RESET} from '../reducer/planning';
import { AppMission } from '../../utils/types/planningTypes';
import { ThunkAction } from 'redux-thunk';
import { CHANGE_EVENTS, CHANGE_LOADING_EVENTS, CHANGE_LOADING_MISSIONS, CHANGE_MISSIONS } from '../reducer/events';
import { UserEventApp } from '../../utils/types/generalTypes';

export type Effect = ThunkAction<any, PlanningState, any, StoreAction>;

export const changeMissions = (missions: AppMission[]): StoreAction => {
    return { type: CHANGE_MISSIONS, data: missions };
}
export const changeLoadingMissions = (loading: boolean): StoreAction => {
    return { type: CHANGE_LOADING_MISSIONS, data: loading };
}
export const changeEvents = (events: UserEventApp[]): StoreAction => {
    return { type: CHANGE_EVENTS, data: events };
}
export const changeLoadingEvents = (loading: boolean): StoreAction => {
    return { type: CHANGE_LOADING_EVENTS, data: loading };
}
/**
 * Reset events reducer
 */
export const resetEvents = () => ({ type: RESET });
export const reset = () => ({ type: RESET });