import {combineReducers} from 'redux';
import user from './user'; 
import window from './window';
import planning from './planning';
import teamManagement from './teamManagement';
import configurations from './configurations';
import events from './events';

const rootReducer = combineReducers({
    user,
    window,
    planning,
    teamManagement,
    configurations,
    events,
});

export default rootReducer;
