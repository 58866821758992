
import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { changeAppVersion } from '../../store/actions/configurations';
import Network from '../../utils/network';
import { AppVersion } from '../../utils/types/generalTypes';
import { showNotification } from '../../utils/utils';

interface IProps {
    children: React.ReactNode;
}

type Props = IProps;

const CheckVersion = (props: Props) => {
    //const eventSelected = useSelector((state: ApplicationState) => state.user.eventSelected);
    //const defaultsLoading = useRef<boolean>(false)
    const versionLoading = useRef<boolean>(false);

    // Redux dispatch
    const dispatch = useDispatch();

    const [localAppVersion, setLocalAppVersion] = useState<AppVersion | undefined>(undefined);
    const [serverAppVersion, setServerAppVersion] = useState<AppVersion | undefined>(undefined);
    const interval = useRef<NodeJS.Timer | undefined>(undefined);

    const intl = useIntl();

    console.log("KROK_VERSION", localAppVersion, serverAppVersion);

    const refreshAppVersion = useCallback(() => {
        let appName: string | undefined | JSX.Element = process.env.REACT_APP_NAME;
        if (appName === undefined) {
            showNotification(intl.formatMessage({ defaultMessage: 'There was a problem checking for updates' }), "error");
            return;
        }

        if (!versionLoading.current) {
            versionLoading.current = true;

            Network.getAppVersion(appName).then(
                (response: AppVersion) => {
                    setServerAppVersion(response);
                    if (!isEqual(localAppVersion, response)) {
                        setLocalAppVersion(response);
                        dispatch(changeAppVersion(response));
                    }
                    versionLoading.current = false;
                },
                () => {
                    versionLoading.current = false;
                    showNotification(intl.formatMessage({ defaultMessage: 'There was a problem checking for updates' }), "error");
                }
            );
        }

    }, [dispatch, intl, localAppVersion]);

    const launchInterval = useCallback((time = 3600000) => {
        //let newInterval: NodeJS.Timer
        if (interval.current === undefined) {
            console.log("VERSION INTERVAL STARTED");
            interval.current = setInterval(() => {
                refreshAppVersion();
                console.log(`VERSION This will run every ${(time / 1000)} seconds ! `);
            }, time);
        } else {
            console.log("VERSION INTERVAL ALREADY STARTED");
        }

    }, [refreshAppVersion]);

    useEffect(() => {
        return () => {
            console.log("VERSION INTERVAL STOPPED");
            clearInterval(interval.current);
            interval.current = undefined;
        };
    }, []);

    useEffect(() => {
        if (localAppVersion === undefined) {
            refreshAppVersion();
        }
        if (interval.current === undefined) {
            launchInterval(900000);
        }

    }, [refreshAppVersion, launchInterval, localAppVersion]);

    useEffect(() => {
        if (localAppVersion?.version !== serverAppVersion?.version)
            showNotification(intl.formatMessage({ defaultMessage: 'An update is available' }), "update", intl.formatMessage({ defaultMessage: 'From version {oldVersion} to {newVersion}' }, { oldVersion: localAppVersion?.version, newVersion: serverAppVersion?.version }), intl);
    }, [intl, localAppVersion?.version, serverAppVersion]);

    console.log("VERSION INTERVAL:", interval.current);
    return (
        <>

            {
                props.children
            }
        </>
    );
};

export default CheckVersion;
