import { ConfigProvider } from 'antd';
import React from 'react';
import { browserVersion, isChrome, isEdge, isEdgeChromium, isFirefox } from "react-device-detect";
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Store } from 'redux';
import { changeHeight, changeWidth } from './store/actions/window';
import { StoreDispatch } from './utils/types/storeTypes';

import frFR from 'antd/lib/locale/fr_FR';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import Root from './components/root/root';

import './App.less';
import './styles/animations.css';
import './styles/variables.css';
// import './styles/fields.css';
// import './styles/navigations.css';
import './styles/antd-modifications.css';
import './styles/general.css';
import './styles/login.css';
import './styles/loginSMS.css';
// import './styles/container.css';
// import './styles/planning.css';
// import './styles/monthlyPlanning.css';
// import './styles/report.css';
// import './styles/crm.css';
// import './styles/configurations.css';
// import './styles/wrongBrowser.css';
// import './styles/dashboard.css';


interface Props { store: Store; }
interface State {
  correctBrowser: boolean;
}

const CHROME_VERSION: number = 80;
const FIREFOX_VERSION: number = 35;

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      correctBrowser: true,
    };
  }
  componentDidMount() {
    this.browserCheck();
    //add a listener to be notified when the window is resized
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('storage', this.handleStorage);
  }

  componentWillUnmount() {
    //remove listener
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('storage', this.handleStorage);
  }


  /**
   * Called when the window is resized
   */
  handleResize = () => {
    //change the width and height value in redux store
    const windowWidth: number = typeof window !== "undefined" ? window.innerWidth : 0;
    const windowHeight: number = typeof window !== "undefined" ? window.innerHeight : 0;
    (this.props.store.dispatch as StoreDispatch)(changeHeight(windowHeight));
    (this.props.store.dispatch as StoreDispatch)(changeWidth(windowWidth));
  };

  handleStorage = () => window.location.reload();

  browserCheck = () => {
    if (isChrome && parseInt(browserVersion) <= CHROME_VERSION) {
      this.setState({ correctBrowser: false });
    } else if (isFirefox && parseInt(browserVersion) <= FIREFOX_VERSION) {
      this.setState({ correctBrowser: false });
    } else if (isEdge && !isEdgeChromium) {
      this.setState({ correctBrowser: false });
    } else {
      this.setState({ correctBrowser: true });
    }
  };

  render() {
    return (
      <Provider store={this.props.store}>
        <ConfigProvider locale={frFR}>
          <Router>
            <Root />
          </Router>
        </ConfigProvider>
      </Provider>
    );
  }

}

export default App;
