import { Layout as AntLayout } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import Cookie from 'js-cookie';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link, Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import Logo from '../../images/logo/barry_events_full2.png';
import LanguageProvider from '../../languageProvider';
import Network from '../../utils/network';
import { User } from '../../utils/types/generalTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import LanguageMenu from '../common/fields/languageMenu';
import MenuComponent from '../menu/menu';
import CheckVersion from './checkVersion';
import Private from './private';

export const Layout = () => {
    const location = useLocation();
    const { lang } = useParams();
    const userId = Cookie.get('userId');

    const url = location.pathname.split("/");
    if (url.length > 2 && url[2] !== undefined && url[2] !== '' && lang !== undefined && ["en", "fr", "de", "it"].includes(lang)) {
        return <Outlet />;
    } else {
        let locale = 'fr';
        if (lang !== undefined && ["en", "fr", "de", "it"].includes(lang)) {
            locale = lang;
        }
        if (userId) {
            return <Navigate to={`/${locale}/dashboard`} replace />;
        } else {
            return <Navigate to={`/${locale}/login`} replace />;
        }

    }
};
export default Layout;

export const changeLanguage = (currentUser?: User, lang?: string) => {
    if (currentUser && lang && currentUser.language !== lang) {
        Network.changeUserLanguage(currentUser.id, lang);
    }
};

export const LocaleLayout = () => {
    const location = useLocation();
    const { lang } = useParams();
    const userId = Cookie.get('userId');
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser);

    const url = location.pathname.split("/");
    if (url.length > 2 && url[2] !== undefined && url[2] !== '' && lang !== undefined && ["en", "fr", "de", "it"].includes(lang)) {
        if (userId) {
            changeLanguage(currentUser, lang);
            moment.locale(lang ? lang : 'fr');
            return (
                <LanguageProvider>
                    <Private>
                        <CheckVersion>
                            <AntLayout className="layout-container">
                                <Header>
                                    <div className="logo" style={{ float: 'right' }} >
                                        <img style={{ maxHeight: '32px' }} src={Logo} alt="logo" />
                                    </div>
                                    <LanguageMenu lang={lang} navigateTo='dashboard' />
                                    <MenuComponent />
                                </Header>
                                <Content>
                                    <Outlet />
                                </Content>
                                <Footer style={{ textAlign: 'center', backgroundColor: '#ffffff' }}>
                                    {`Barry ${moment().format('YYYY')} © Powered by `}<Link to='https://webevolutions.ch/fr/'>WebEvolutions</Link>
                                </Footer>
                            </AntLayout>
                        </CheckVersion>
                    </Private >
                </LanguageProvider>
            );
        } else {
            return <LanguageProvider><Outlet /></LanguageProvider>;
        }
    } else {
        let locale = 'fr';
        if (lang !== undefined && ["en", "fr", "de", "it"].includes(lang)) {
            locale = lang;
        }
        if (userId) {
            return <Navigate to={`/${locale}/dashboard`} replace />;
        } else {
            return <Navigate to={`/${locale}/login`} replace />;
        }

    }
};


export const NotFoundLayout = () => {
    const { lang } = useParams();
    const userId = Cookie.get('userId');

    let locale = 'fr';
    if (lang !== undefined && ["en", "fr", "de", "it"].includes(lang)) {
        locale = lang;
    }

    if (userId) {
        return <Navigate to={`/${locale}/company/dashboard`} replace />;

    } else {
        return <Navigate to={`/${locale}/login`} replace />;
    }
};