import { Button, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { LoginUserSmall } from "../../../utils/types/generalTypes";

interface Props {
    setUser: (user: LoginUserSmall | undefined) => void;
}

const LoginInactiveView = (props: Props) => {
    return (
        <>
            <Col xs={{ span: 24 }} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <p style={{ margin: '0px auto 10px auto', fontSize: 20, fontWeight: 'bold' }}>
                    <FormattedMessage defaultMessage={'User deactivated'} />
                </p>
                <p style={{ textAlign: 'center' }}>
                    <FormattedMessage defaultMessage={'You cannot login to Barry. If this is an error, please contact your administrator.'} />
                </p>
            </Col>
            <Col xs={{ span: 24 }} style={{ display: 'flex' }}>
                <Button
                    style={{ margin: 'auto' }}
                    onClick={() => props.setUser(undefined)}
                >
                    <FormattedMessage defaultMessage={'To login page'} />
                </Button>
            </Col>
        </>
    );
};

export default LoginInactiveView;