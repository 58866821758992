import { StoreAction, PlanningState } from '../../utils/types/storeTypes';

export const PLANNING_INIT_STATE: PlanningState = {
    userRows: [],
    loadingPlanning: false,
    selectGroups: undefined,
    selectUsers: undefined,
    displayWorkingTime: true,
    templates: [],
    settings: {},
    cellsPerRow: undefined,
    draggedGroupEventUserId: undefined,
    draggedUserEventUserId: undefined,
    // shared methods
    onClickEvent: undefined,
    onEditEvent: undefined,
    onDeleteEvent: undefined,
    onEditOvertime: undefined,
    onDeleteOvertime: undefined,
};

export const TOGGLE_LOADING_PLANNING = Symbol('TOGGLE_LOADING_PLANNING');
export const CHANGE_USER_ROWS = Symbol('CHANGE_USER_ROWS');
export const CHANGE_SELECT_GROUPS = Symbol('CHANGE_SELECT_GROUPS');
export const CHANGE_SELECT_USERS = Symbol('CHANGE_SELECT_USERS');
export const TOGGLE_DISPLAY_WORKING_TIME = Symbol('TOGGLE_DISPLAY_WORKING_TIME');
export const CHANGE_TEMPLATES = Symbol('CHANGE_TEMPLATES');
export const CHANGE_SETTINGS = Symbol('CHANGE_SETTINGS');
export const CHANGE_CELLS_PER_ROW = Symbol('CHANGE_CELLS_PER_ROW');
export const CHANGE_DRAGGED_GROUP_EVENT_USER_ID = Symbol('CHANGE_DRAGGED_GROUP_EVENT_USER_ID');
export const CHANGE_DRAGGED_USER_EVENT_USER_ID = Symbol('CHANGE_DRAGGED_USER_EVENT_USER_ID');
export const SET_EVENT_METHODS = Symbol('SET_EVENT_METHODS');
export const RESET = Symbol('RESET');

const planning = (state: PlanningState = PLANNING_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case TOGGLE_LOADING_PLANNING:
            return {
                ...state,
                loadingPlanning: action.data,
            }
        case CHANGE_USER_ROWS:
            return {
                ...state,
                userRows: [...action.data],
            }
        case CHANGE_SELECT_GROUPS:
            return {
                ...state,
                selectGroups: action.data,
            }
        case CHANGE_SELECT_USERS:
            return {
                ...state,
                selectUsers: action.data,
            }
        case TOGGLE_DISPLAY_WORKING_TIME:
            return {
                ...state,
                displayWorkingTime: !state.displayWorkingTime,
            };
        case CHANGE_TEMPLATES:
            return {
                ...state,
                templates: action.data,
            }
        case CHANGE_SETTINGS:
            return {
                ...state,
                settings: action.data,
            };
        case CHANGE_CELLS_PER_ROW:
            return {
                ...state,
                cellsPerRow: action.data,
            }
        case CHANGE_DRAGGED_GROUP_EVENT_USER_ID:
            return {
                ...state,
                draggedGroupEventUserId: action.data,
            }
        case CHANGE_DRAGGED_USER_EVENT_USER_ID:
            return {
                ...state,
                draggedUserEventUserId: action.data,
            };
        case SET_EVENT_METHODS:
            return {
                ...state,
                onClickEvent: action.data.onClickEvent,
                onEditEvent: action.data.onEditEvent,
                onDeleteEvent: action.data.onDeleteEvent,
                onEditOvertime: action.data.onEditOvertime,
                onDeleteOvertime: action.data.onDeleteOvertime,
            }
        case RESET:
            return PLANNING_INIT_STATE;
        default:
            return state;
    }
};

export default planning;