import { Button, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { LoginUserSmall } from "../../../utils/types/generalTypes";
import LoginCodeView from "./loginCodeView";

interface Props {
    setForgotPassword: (forgotPassword: boolean) => void;
    user: LoginUserSmall;
    setUser: (user: LoginUserSmall | undefined) => void;
    setCode: (code: string) => void;
}

const LoginForgotView = (props: Props) => {
    return (
        <>
            {
                props.user.isSmsEligible ?
                    <LoginCodeView
                        user={props.user}
                        setCode={props.setCode}
                        setUser={props.setUser}
                        forgot
                        setForgot={props.setForgotPassword}
                    />
                    :
                    <>
                        <Col xs={{ span: 24 }} style={{ display: 'flex', flexDirection: 'column' }}>
                            <p style={{ margin: '0px auto 10px auto', fontSize: 18, fontWeight: 'bold' }}>
                                <FormattedMessage defaultMessage={'Password reset'} />
                            </p>
                            <p style={{ textAlign: 'center' }}>
                                <FormattedMessage defaultMessage={"You can't reset your password with the SMS code. Contact your administrator to reset your password"} />
                            </p>
                        </Col>
                        <Col xs={{ span: 24 }} style={{ display: 'flex' }}>
                            <Button
                                style={{ margin: 'auto' }}
                                onClick={() => props.setForgotPassword(false)}
                            >
                                <FormattedMessage defaultMessage={'Back'} />
                            </Button>
                        </Col>
                    </>
            }
        </>
    );
};

export default LoginForgotView;