import { ThunkAction } from 'redux-thunk';
import { StoreAction, WindowState } from '../../utils/types/storeTypes';
import { CHANGE_LANGUAGE, RESET, TYPE_WINDOW_HEIGHT, TYPE_WINDOW_NAVIGATION_COLLAPSED, TYPE_WINDOW_WIDTH } from '../reducer/window';

export type Effect = ThunkAction<any, WindowState, any, StoreAction>;

/**
 * Update window's width props with given width
 * @param width the new width
 */
export const changeWidth = (width: number): Effect => (dispatch, getState): void => {
    dispatch({type: TYPE_WINDOW_WIDTH, data: width});
};

/**
 * Update window's height props with given height
 * @param height the new height
 */
export const changeHeight = (height: number): Effect => (dispatch, getState): void => {
    dispatch({type: TYPE_WINDOW_HEIGHT, data: height});
};


/**
 * Toggle collpase state of the side navigation bar
 */
export const toggleNavigationCollapsed = (): Effect => (dispatch, getState): void => {
    dispatch({type: TYPE_WINDOW_NAVIGATION_COLLAPSED});
}

export const changeLanguage = (language: string): StoreAction => ({type: CHANGE_LANGUAGE, data: language})

export const reset = () => ({ type: RESET });