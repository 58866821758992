import { Route, Routes } from "react-router-dom";
import Homepage from "../homepage/homepage";
import LoginSMS from "../login/loginSMS";
import Layout, { LocaleLayout, NotFoundLayout } from '../specialRoutes/layouts';
import NotPrivate from '../specialRoutes/notPrivate';

const Root = () => {


    return (
        <>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path=':lang' element={<LocaleLayout />}>
                        <Route path="dashboard" element={<Homepage />} />
                        <Route path="login" element={<NotPrivate><LoginSMS /></NotPrivate>} />
                        <Route path="*" element={<NotFoundLayout />} />
                    </Route>
                </Route>
            </Routes>
        </>
    );
};

export default Root;