import ReactCodeInput, { ReactCodeInputProps } from "react-code-input";

interface Props extends ReactCodeInputProps {
    onEnter: Function;
}

const CodeField = (props: Props) => {

    const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
        if (e.key === 'Enter') {
            props.onEnter();
        }
    };

    return (
        <span onKeyDown={handleKeyDown}>
            <ReactCodeInput
                {...props}
            />
        </span>
    );
};

export default CodeField;