// access to google storage
export const GOOGLE_STORAGE_URL = 'https://storage.googleapis.com/';


export const MOMENT_FORMAT_DISPLAY = "DD/MM/YYYY à HH:mm:ss";
export const MOMENT_FORMAT_DISPLAY_SHORT = "DD/MM/YY HH:mm";
export const MOMENT_FORMAT = "YYYY-MM-DDTHH:mm:00";
export const MOMENT_MONTH_FORMAT = "YYYY-MM-DD";
export const MOMENT_CCNT_MONTH_FORMAT = "MM/YYYY";
export const MOMENT_CCNT_DAY_FORMAT = "DD/MM/YYYY";

export const YELLOW_COLOR = "#fbc02d";
export const RED_COLOR = "#e53935";
export const GREEN_COLOR = "#558b2f"

export const AvailableLanguages = [
    { label: 'English', value: 'en' },
    { label: 'Français', value: 'fr' },
    { label: 'Deutsch', value: 'de' },
    { label: 'Italiano', value: 'it' }
];