import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { Col, Modal, Row, Spin } from 'antd';
import Title from "antd/lib/typography/Title";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { changeLoadingMissions, changeMissions } from "../../store/actions/events";
import getFormat from '../../utils/Lang';
import Network from "../../utils/network";
import { AppMission } from "../../utils/types/planningTypes";
import { ApplicationState } from "../../utils/types/storeTypes";
import { showNotification } from "../../utils/utils";
import CustomIcon from '../common/general/customIcon';
import MissionDetail from "../missionDetail/missionDetail";

interface IProps {
    eventId: number;
}

type Props = IProps;

const EventDetail = (props: Props) => {
    const navigate = useNavigate();
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser);
    const dispatch = useDispatch();
    // const [eventId, setEventId] = useState<number>();
    const [missions, setMissions] = useState<AppMission[]>();
    const [teamMission, setTeamMission] = useState<boolean>(true);
    const [usersMissions, setUsersMissions] = useState<AppMission[]>();
    const [mission, setMission] = useState<AppMission>();
    const { lang } = useParams();

    const intl = useIntl();

    const closeMission = () => {
        setMission(undefined);
    };
    const openMission = (mission: AppMission, teamMission: boolean = false) => {
        setMission(mission);
        setTeamMission(teamMission);
    };
    const updateIsInPlace = (missionId: number) => {
        if (usersMissions?.findIndex(um => um.id === missionId) !== -1) {
            let newMissions = cloneDeep(usersMissions);
            let missionFounded = newMissions?.find(m => m.id === missionId);
            if (missionFounded) {
                missionFounded.isInPlace = true;
            }
            setUsersMissions(newMissions);
        }
        else {
            let newMissions = cloneDeep(missions);
            let missionFounded = newMissions?.find(m => m.id === missionId);
            if (missionFounded) {
                missionFounded.isInPlace = true;
            }
            setMissions(newMissions);
        }
    };
    const updateConfirmed = (missionId: number) => {
        let newMissions = cloneDeep(missions);
        let missionFounded = newMissions?.find(m => m.id === missionId);
        if (missionFounded) {
            missionFounded.confirmed = true;
        }
        setMissions(newMissions);
    };

    useEffect(() => {
        if (currentUser && props.eventId) {
            dispatch(changeLoadingMissions(true));
            Network.getMissionsApp(props.eventId).then(
                (response) => {
                    if (response.status === "Success") {
                        setMissions(response.data);
                        setUsersMissions(response.usersData);
                    } else {
                        dispatch(changeMissions([]));
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading missions' }), "error");
                    }
                },
                error => {
                    if (error.message === "AUTH_FAILED") {
                        navigate(`${lang}/login`);
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading missions' }), "error");
                    }
                }
            );
        }
    }, [currentUser, dispatch, navigate, props.eventId, lang, intl]);

    console.log("KROK", missions);

    return (
        <Row gutter={[10, 10]}>

            <Col xs={{ span: 24 }}>
                <Title level={2}><FormattedMessage defaultMessage={'My missions:'} /></Title>
            </Col>
            {!missions ?
                <Col xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                    <Spin />
                </Col>
                :
                missions.length === 0 ?
                    <Col xs={{ span: 24 }}>
                        <p><FormattedMessage defaultMessage={'No mission available'} /></p>
                    </Col>
                    :
                    <>
                        {
                            missions.map(m => {
                                return (
                                    <Col key={`eventdetail-event${props.eventId}-user${currentUser?.id}-mission${m.id}`} xs={{ span: 24 }}
                                        onClick={() => {
                                            // navigate(
                                            //     `/event/${props.eventId}/mission/${m.id}`,
                                            //     { state: { mission: m, eventId: props.eventId } }
                                            // )
                                            openMission(m);
                                        }}
                                    >
                                        <div className={`__missions-card ${m.isInPlace ? '__missions-card-green' : m.isInPlaceTime ? '__missions-card-red' : ''}`}>
                                            {/* <CardA
                                            // title={<span><Avatar size="large" src={GOOGLE_STORAGE_URL + event.logo} /> {event.title}</span>}
                                            title={m.title}
                                            onClick={() => {
                                                navigate(
                                                    `/event/${eventId}/mission/${m.id}`,
                                                    { state: { mission: m, eventId: eventId } }
                                                )
                                            }}
                                        >
                                            <p>Lieu: {m.poi.title}</p>
                                            <p>Aptitude: {m.staffType.name}</p>
                                            <p>Début: {moment(m.startDate).format("DD.MM.YYYY")} - Fin: {moment(m.endDate).format("DD.MM.YYYY")}</p>
                                            <p>{m.description}</p>
                                        </CardA> */}
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                <p style={{ maxWidth: 'calc(100vw - 120px)', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '100%' }}>
                                                    {moment(m.startDate).format("DD MMM")} <span style={{ fontSize: "90%" }}><span style={{ color: '#00B0EF' }}>{moment(m.startDate).format(getFormat('TIME_SHORT'))}</span> - <span style={{ color: '#00B0EF' }}>{moment(m.endDate).format(getFormat('TIME_SHORT'))}</span></span>
                                                </p>
                                                <p style={{ fontSize: '85%' }}>{m.title} </p>
                                            </div>
                                            <CustomIcon icon={faChevronRight} color='#00B0EF' />
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </>
            }
            {!usersMissions ?
                <Col xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                    <Spin />
                </Col>
                :
                usersMissions.length === 0 ?
                    null
                    :
                    <>
                        <Col xs={{ span: 24 }} style={{ marginTop: "10px" }}>
                            <Title level={2}><FormattedMessage defaultMessage={"My team's missions"} /></Title>
                        </Col>
                        {
                            usersMissions.map(m => {
                                return (
                                    <Col key={`eventdetail-userevent${props.eventId}-user${currentUser?.id}-mission${m.id}`} xs={{ span: 24 }}
                                        onClick={() => {
                                            openMission(m, true);
                                        }}
                                    >
                                        <div className={`__missions-card ${m.isInPlace ? '__missions-card-green' : m.isInPlaceTime ? '__missions-card-red' : ''}`}>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                <p style={{ maxWidth: 'calc(100vw - 120px)', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '100%' }}>
                                                    {moment(m.startDate).format("DD MMM")} <span style={{ fontSize: "90%" }}><span style={{ color: '#00B0EF' }}>{moment(m.startDate).format(getFormat('TIME_SHORT'))}</span> - <span style={{ color: '#00B0EF' }}>{moment(m.endDate).format(getFormat('TIME_SHORT'))}</span></span>
                                                </p>
                                                <p style={{ fontSize: '85%' }}>{m.title} </p>
                                                <p style={{ fontSize: '85%', fontWeight: 'bold' }}>{m.username} </p>
                                            </div>
                                            <CustomIcon icon={faChevronRight} color='#00B0EF' />
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </>
            }
            <Modal
                destroyOnClose
                centered
                maskClosable={false}
                onCancel={closeMission}
                title={mission?.title}
                visible={mission !== undefined}
                footer={null}
            >
                {
                    mission &&
                    <MissionDetail teamMission={teamMission} updateIsInPlace={updateIsInPlace} updateIsConfirmed={updateConfirmed} mission={mission} eventId={props.eventId} />
                }
            </Modal>
        </Row>
    );
};
export default EventDetail;