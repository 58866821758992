import * as api from './api';

/**
 * Static class with Network API call methods
 */
export default class Network {
  static getAppVersion = (appName: string): Promise<any> => api.apiRequestGet(`utils/app/version?app_name=${appName}`);

  // User

  /**
   * Login the user
   * @param username the user's username
   * @param password the user's password
   * @returns a promise containing the response of the request
   */
  static login = (userId: number, password: string, rememberMe?: boolean): Promise<any> => api.login(userId, password, rememberMe);
  static logout = (): Promise<any> => api.apiRequestGet('user/logout');

  /**
    * Get the current user details
    * @returns a promise containing the response of the request
    */
  static getUserDetails = (): Promise<any> => api.apiRequestGet('user/app/current');

  /**
   * Start the process for a forgotten password
   * @param email the user's email
   * @returns a promise containing the response of the request
   */
  static forgotPassword = (email: string): Promise<any> => api.apiRequest('user/forgot-password/', { email });

  /**
   * Get events
   * @param eventId the event's id
   * @returns a promise containing the response of the request
   */
  static getMissionsApp = (eventId: number): Promise<any> => api.apiRequestGet(`planning/app/missions?eventId=${eventId}`);

  /**
   * Get all projects
   * @returns a promise containing the response of the request
   */
  static getEvents = (eventId?: number): Promise<any> => api.apiRequestGet(`planning/app/events${eventId ? "?eventId=" + eventId : ''}`);

  /**
   * Get poi => if poiId get one, else get all
   * @returns a promise containing the response of the request
   */
  static getPoi = (event: number, poiId?: number): Promise<any> => api.apiRequestGet(`location/app/poi?eventId=${event}${poiId ? `&poiId=${poiId}` : ''}`);
  /**
   * Get User event from the app => if userEventId get one, else get all
   * @returns a promise containing the response of the request
   */
  static getUserEventsApp = (userEventId?: number): Promise<any> => api.apiRequestGet(`user/app/users-event${userEventId ? `?userEventId=${userEventId}` : ''}`);

  static setConfirmed = (isConfirmed: boolean, missionId: number, eventId: number): Promise<any> => api.apiRequest('planning/app/mission/confirmed/', {
    missionId,
    isConfirmed,
    eventId,
  });
  static setIsInPlace = (isInPosition: boolean, accuracy: number, missionId: number, latitude: number, longitude: number, eventId: number): Promise<any> => api.apiRequest('planning/app/mission/is-in-place/', {
    missionId,
    isInPosition,
    accuracy,
    latitude,
    longitude,
    eventId,
  });

  static changeUserLanguage = (userId: number, lang: string) => api.apiRequest('user/language', {user_id: userId, lang: lang})

  /**
   * Get User eligiblity and vital informations to continue login process
   * @param username The user's username
   * @returns a promise containing the response of the request
   */
  static loginMobile = (username: string) => api.apiRequest('user/login/mobile', {username: username})

  /**
   * Generate and send an SMS code to the user
   * @param userId The user's ID
   * @param force Force the generation
   * @returns a promise containing the response of the request
   */
  static loginCodeSend = (userId: number, force?: boolean) => api.apiRequest('user/login/mobile/code', {userId: userId, action: 'send', force: force})

  /**
   * Verfiy the user's code they received
   * @param userId The user's ID
   * @param code SMS Code the user's received
   * @returns a promise containing the response of the request
   */
  static loginCodeVerify = (userId: number, code: string) => api.apiRequest('user/login/mobile/code', {userId: userId, action: 'verify', code: code})

  /**
   * Set a new permanent password after first login or password reset
   * @param userId The user's ID
   * @param code Code they receives via SMS
   * @param password New password
   * @returns a promise containing the response of the request
   */
  static loginPasswordSet = (userId: number, code: string, password: string) => api.loginPasswordSet(userId, code, password)
}