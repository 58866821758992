
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { Col, Row, Spin } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { changeUserDetails } from '../../store/actions/user';
import Network from '../../utils/network';
import { User } from '../../utils/types/generalTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import { showNotification } from '../../utils/utils';
import CustomIcon from '../common/general/customIcon';
import { changeLanguage } from './layouts';

interface IProps {
    children: React.ReactNode;
}

type Props = IProps;

const WaitLoadUser = (props: Props) => {
    const userLoading = useRef<boolean>(false);

    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { lang } = useParams();

    const refreshUser = useCallback(() => {
        if (!userLoading.current) {
            userLoading.current = true;

            Network.getUserDetails().then(
                (response: User) => {
                    let browserLang = lang;
                    if (browserLang === undefined) {

                        if (['fr', 'de', 'it', 'en'].includes(response.language)) {
                            browserLang = response.language;
                        }
                        else {
                            browserLang = 'fr';
                            response.language = 'fr';
                        }
                    }

                    if (response.language !== lang) {
                        changeLanguage(response, lang);
                    }

                    dispatch(changeUserDetails(response));
                    userLoading.current = false;
                    navigate(`/${response.language}/dashboard`);
                },
                () => {
                    userLoading.current = false;
                    showNotification('An issue occurred while loading the user', "warning");
                },
            );
        }

    }, [dispatch, lang, navigate]);



    useEffect(() => {
        if (currentUser === undefined) {
            refreshUser();
        }
    }, [refreshUser, currentUser]);

    console.log("CURRENTUSER", currentUser);
    return (
        <>

            {
                !currentUser ?
                    <Row>
                        <Col span={24}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', flexDirection: 'column' }}>
                                <Spin size='large' style={{ marginBottom: '20px' }} indicator={<CustomIcon icon={faSpinnerThird} duoTone="#00B0EF" iconSize={100} spin />} />
                            </div>
                        </Col>
                    </Row>
                    :
                    props.children
            }
        </>
    );
};

export default WaitLoadUser;
