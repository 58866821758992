
import Cookie from 'js-cookie';
import { Navigate, useParams } from "react-router-dom";
import WaitLoadUser from './waitLoadUser';


interface IProps {
    children: JSX.Element;
}

type Props = IProps;

function Private(props: Props): JSX.Element {
    const { lang } = useParams();

    const userId = Cookie.get("userId");
    if (userId !== undefined) {
        return <WaitLoadUser>{props.children}</WaitLoadUser>;
    } else {
        let locale = 'fr';
        if (lang !== undefined && ["en", "fr", "de", "it"].includes(lang)) {
            locale = lang;
        }
        return <Navigate to={`/${locale}/login`} replace={true} />;
    }
};

export default Private;
