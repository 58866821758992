import { Button, Col } from "antd";
import Password from "antd/lib/input/Password";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { changeUserDetails } from "../../../store/actions/user";
import Network from "../../../utils/network";
import { LoginUserSmall } from "../../../utils/types/generalTypes";
import { showNotification } from "../../../utils/utils";

interface Props {
    user: LoginUserSmall;
    code: string;
}

const LoginPasswordView = (props: Props) => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { lang } = useParams();

    const [password, setPassword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const setPermanentPassword = () => {
        setLoading(true);

        Network.loginPasswordSet(props.user.id, props.code, password).then(
            (response) => {
                dispatch(changeUserDetails(response));
                navigate(`/${lang}/dashboard`);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occured while updating your password' }), 'error');
                setLoading(false);
            }
        );
    };

    return (
        <>
            <Col xs={{ span: 24 }} style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ margin: '0px auto 10px auto', fontSize: 18, fontWeight: 'bold' }}>
                    <FormattedMessage defaultMessage={'Set your new password'} />
                </p>
                <p style={{ textAlign: 'center' }}>
                    <FormattedMessage defaultMessage={"Set your own personnal password. Please remember this password. The administrators won't ask you your password. Don't ever give it to anyone."} />
                </p>
            </Col>
            <Col xs={{ span: 24 }}>
                <Password
                    className="login-input"
                    onChange={(e: any) => setPassword(e.target.value)}
                    onPressEnter={setPermanentPassword}
                    value={password}
                    placeholder={intl.formatMessage({ defaultMessage: 'Password' })}
                    autoFocus />
            </Col>
            <Col xs={{ span: 24 }} style={{ display: 'flex' }}>
                <Button
                    style={{ margin: 'auto' }}
                    loading={loading}
                    type='primary'
                    onClick={setPermanentPassword}>
                    <FormattedMessage defaultMessage={'Save and login'} />
                </Button>
            </Col>
        </>
    );
};

export default LoginPasswordView;