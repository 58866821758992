import React, { CSSProperties, useLayoutEffect, useState } from 'react';
import { Rnd } from 'react-rnd';
import { PoiActiveImage } from '../../utils/types/generalTypes';
interface IProps {
    id?: number;
    activeIcon: PoiActiveImage;
    parentHeight: number;
    parentWidth: number;

}

const IconComponent = (props: IProps) => {
    const [position, setPosition] = useState({
        x: -1,
        y: -1,
        width: 5,
        height: 5
    });



    useLayoutEffect(() => {
        if (props.parentWidth !== undefined && props.parentWidth !== 0 && props.parentHeight !== undefined && props.parentHeight !== 0 && position && position.x === -1 && position.y === -1) {

            setPosition({
                x: props.activeIcon.positionX < 1 ? (Math.round((1 * props.parentWidth) / 100)) : props.activeIcon.positionX > 99 ? (Math.round((99 * props.parentWidth) / 100)) : (Math.round((props.activeIcon.positionX * props.parentWidth) / 100)),
                y: props.activeIcon.positionY < 1 ? (Math.round((1 * props.parentHeight) / 100)) : props.activeIcon.positionY > 99 ? (Math.round((99 * props.parentHeight) / 100)) : (Math.round((props.activeIcon.positionY * props.parentHeight) / 100)),
                width: Math.round((props.activeIcon.width * props.parentWidth) / 100),
                height: Math.round((props.activeIcon.height * props.parentWidth) / 100)
            })
        }

    }, [props.parentWidth, props.parentHeight, props.activeIcon.positionX, props.activeIcon.positionY, props.activeIcon.width, props.activeIcon.height, position])


    const iconstyle: CSSProperties = {
        width: "100%",
        height: "100%",
        transform: `rotate(${props.activeIcon.rotation}deg)`,
        backgroundImage: `url(${"https://storage.googleapis.com/" + props.activeIcon.icon?.image})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }


    return (
        <>
            {props.activeIcon.positionX !== 0 && props.activeIcon.positionY !== 0 && position.x !== -1 && position.y !== -1 ?
                <Rnd
                    default={position}
                    disableDragging
                    enableResizing={false}
                    bounds="#actual-image"
                    lockAspectRatio={true}
                    className={"box"}
                >
                    {
                        props.activeIcon.icon ?
                            <div style={iconstyle} />
                            :
                            <></>
                    }

                </Rnd>

                : ""}
        </>
    )
};

const areEqual = (prevProps: any, nextProps: any) => {

    if (prevProps.icon !== nextProps.icon) {
        return false                                    //  re-render
    }
    if ((prevProps.parentHeight !== nextProps.parentHeight) &&
        (prevProps.parentWidth !== nextProps.parentWidth) &&
        (nextProps.parentHeight !== undefined && nextProps.parentWidth !== undefined)) {
        return false
    }
    return true                             // do not re-render
}

export default React.memo(IconComponent, areEqual)