import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

interface IProps {
    iconSize?: number
    duoTone?: string
    color?: string
}

type Props = IProps & FontAwesomeIconProps

const CustomIcon = (props: Props) => {
    const style = {
        "--fa-primary-color": props.duoTone, // colors defined elsewhere
        "--fa-secondary-color": props.duoTone
    }
    return <FontAwesomeIcon {...props} className={`anticon fontawesome-customicon ${props.className}`} style={{ ...props.style, ...style, fontSize: props.iconSize ? props.iconSize : 16, color: props.color ? props.color : '' }} />
}

export default CustomIcon