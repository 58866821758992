import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { Button, Col, Input } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Network from "../../../utils/network";
import { LoginUserSmall } from "../../../utils/types/generalTypes";
import { NetworkLoginMobile } from "../../../utils/types/networkTypes";
import { showNotification } from "../../../utils/utils";
import CustomIcon from "../../common/general/customIcon";

interface Props {
    setUser: (user: LoginUserSmall | undefined) => void;
    setUsers: (users: LoginUserSmall[]) => void;
}

const LoginDefaultView = (props: Props) => {

    const intl = useIntl();

    const [username, setUsername] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const checkEligibility = () => {
        setLoading(true);
        Network.loginMobile(username).then(
            (res: NetworkLoginMobile) => {
                if (res.data.length > 1) {
                    props.setUsers(res.data);
                }
                else if (res.data.length === 1) {
                    props.setUser(res.data[0]);
                }
                setLoading(false);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'The user can not be found' }), 'error');
                setLoading(false);
            }
        );
    };


    return (
        <>
            <Col xs={{ span: 24 }}>
                <Input
                    className='login-input'
                    onChange={e => setUsername(e.target.value)}
                    value={username}
                    placeholder={intl.formatMessage({ defaultMessage: 'Username' })}
                    onPressEnter={checkEligibility}
                    suffix={<CustomIcon icon={faUser} />}
                    autoFocus
                />
            </Col>
            <Col xs={{ span: 24 }} style={{ display: 'flex' }}>
                <Button
                    style={{ margin: 'auto' }}
                    type='primary'
                    loading={loading}
                    disabled={username === ''}
                    onClick={checkEligibility}>
                    <FormattedMessage defaultMessage={'Login'} />
                </Button>
            </Col>
        </>
    );
};

export default LoginDefaultView;