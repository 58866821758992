import { Avatar, Col, Collapse, Drawer, Row } from 'antd';
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeEvents, changeLoadingEvents } from "../../store/actions/events";
import '../../styles/homepage.less';
import { GOOGLE_STORAGE_URL } from "../../utils/constants";
import Network from "../../utils/network";
import { AppEvent } from "../../utils/types/generalTypes";
import { ApplicationState } from "../../utils/types/storeTypes";
import { showNotification } from "../../utils/utils";
import EventDetail from "../eventDetail/eventDetail";
import LoadingComponent from "../loading/loading";

import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { Document, Page, pdfjs } from 'react-pdf';
import { Link, useNavigate, useParams } from 'react-router-dom';
import getFormat from '../../utils/Lang';
import CustomIcon from '../common/general/customIcon';


pdfjs.GlobalWorkerOptions.workerSrc = "https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js";


const Homepage = () => {
    const dispatch: any = useDispatch();
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser);
    const userEvents = useSelector((state: ApplicationState) => state.events.events);
    const loadingEvents = useSelector((state: ApplicationState) => state.events.loadingEvents);
    const [openPDF, setOpenPDF] = useState<boolean>(false);
    const [numPages, setNumPages] = useState<null | number>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const intl = useIntl();
    const navigate = useNavigate();
    const { lang } = useParams();
    // const [userEvents, setUserEvents] = useState<UserEventApp[]>()


    const onPdfLoadSuccess = ({ nmPages }: any) => {
        if (nmPages !== numPages || pageNumber !== 1) {
            setNumPages(nmPages);
            setPageNumber(1);
        }
    };

    useEffect(() => {
        if (currentUser === undefined)
            navigate(`/${lang}/login`);
    });

    useEffect(() => {
        if (currentUser) {
            dispatch(changeLoadingEvents(true));
            Network.getUserEventsApp().then(
                (response) => {
                    if (response.status === "Success") {
                        dispatch(changeEvents(response.data));
                    } else {
                        dispatch(changeEvents([]));
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading missions' }), "error");

                    }
                }
            );
        }
    }, [currentUser, dispatch, intl]);

    let today = moment();
    let defaultActiveKey: string[] = [];

    userEvents?.forEach(u => {
        let event: AppEvent = u.event;
        let startDate = event.startDate;
        let endDate = event.endDate;
        if (today.isBetween(startDate, endDate, 'day', '[]')) {
            defaultActiveKey.push(event.id.toString());
        }
    });

    return (
        <Row gutter={[10, 10]}>
            {
                userEvents === undefined || loadingEvents ?
                    <Col xs={{ span: 24 }}>
                        <LoadingComponent />
                    </Col>
                    :
                    userEvents.length === 0 ?
                        <Col xs={{ span: 24 }}>
                            <p style={{ textAlign: 'center' }}><FormattedMessage defaultMessage={'No mission available'} /></p>
                        </Col>
                        :
                        <Col xs={{ span: 24 }}>
                            <Title level={1}><FormattedMessage defaultMessage={'My missions:'} /></Title>
                            <Collapse destroyInactivePanel accordion defaultActiveKey={defaultActiveKey}>
                                {
                                    userEvents.map(u => {
                                        return (
                                            <Collapse.Panel
                                                header={<span>{u.event.title}</span>} key={`${u.event.id}`}
                                                extra={<Avatar size="large" src={GOOGLE_STORAGE_URL + u.event.logo} />}
                                            >
                                                {
                                                    u.event.description &&
                                                    <>
                                                        <Title level={2}><FormattedMessage defaultMessage={'Description'} /></Title>
                                                        <p style={{ marginBottom: '20px' }}>{u.event.description}</p>
                                                    </>
                                                }

                                                <Title level={2}><FormattedMessage defaultMessage={'Event dates'} /></Title>
                                                <p style={{ marginBottom: '20px' }}>{moment(u.event.startDate).format(getFormat('FULL_YEAR'))} - {moment(u.event.endDate).format(getFormat('FULL_YEAR'))}</p>

                                                {
                                                    u.event.id === 8 &&
                                                    <>
                                                        <Title level={2}><FormattedMessage defaultMessage={'Security informations'} /></Title>
                                                        {/* <p onClick={() => setOpenPDF(true)} style={{ marginBottom: '20px', cursor: "pointer" }}>Télécharger document <FilePdfOutlined style={{ marginLeft: '5px' }} /></p> */}
                                                        <Link to="https://storage.googleapis.com/webevolutions_images/7_document_barry.pdf?rnd=1" target="_blank" style={{ color: "#000000A6" }}><FormattedMessage defaultMessage={'Open file'} /> <CustomIcon icon={faFilePdf} /></Link>
                                                        <p style={{ marginBottom: '20px', cursor: "pointer" }}></p>
                                                    </>
                                                }
                                                <EventDetail key={`homepage-user-event${u.id}-event${u.event.id}-user${currentUser?.id}`} eventId={u.event.id} />
                                            </Collapse.Panel>
                                        );
                                    })
                                }
                            </Collapse>
                            <Drawer
                                onClose={() => setOpenPDF(false)}
                                visible={openPDF}
                                title={intl.formatMessage({ defaultMessage: 'Procedures Available' })}
                                width={"100%"}
                                footer={null}
                            >
                                {/* <Space>
                                    <CircleButton
                                        disabled={pageNumber <= 1}
                                        icon={<LeftOutlined />}
                                        onClick={() => pageNumber > 1 && setPageNumber(pageNumber - 1)}
                                    />
                                    <CircleButton
                                        disabled={numPages !== null && pageNumber >= numPages}
                                        icon={<RightOutlined />}
                                        onClick={() => numPages !== null && pageNumber < numPages && setPageNumber(2)}
                                    />
                                </Space> */}
                                <Document
                                    file={{ url: "https://storage.googleapis.com/webevolutions_images/7_document_barry.pdf?rnd=1" }}
                                    onLoadSuccess={onPdfLoadSuccess}
                                >
                                    {
                                        Array.from(new Array(numPages), (el, index) => {
                                            console.log("YEAHHHHHHHH", numPages, index);
                                            return <Page key={`page_${index + 1}`} pageNumber={index + 1} />;
                                        })
                                    }
                                </Document>
                            </Drawer>
                        </Col>
            }
        </Row>
    );
};
export default Homepage;