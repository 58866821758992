import moment from "moment";

const defaultFormat = {
    FULL_YEAR: 'MM/DD/YYYY',
    TIME_SHORT: 'HH:mm',
    TIME_LONG: 'HH:mm:ss',
    FULL_YEAR_TIME_SHORT: 'MM/DD/YYYY HH:mm',
    FULL_YEAR_TIME_LONG: 'MM/DD/YYYY HH:mm:ss'
}

type IFormat = typeof defaultFormat

const override: { [key: string]: Partial<IFormat>; } = {
    fr: {
        FULL_YEAR: 'DD.MM.YYYY',
        FULL_YEAR_TIME_SHORT: 'MM.DD.YYYY HH:mm',
        FULL_YEAR_TIME_LONG: 'MM.DD.YYYY HH:mm:ss'
    },
    de: {
        FULL_YEAR: 'DD.MM.YYYY',
        FULL_YEAR_TIME_SHORT: 'MM.DD.YYYY HH:mm',
        FULL_YEAR_TIME_LONG: 'MM.DD.YYYY HH:mm:ss'
    },
    it: {
        FULL_YEAR: 'MM/DD/YYYY',
        FULL_YEAR_TIME_SHORT: 'MM/DD/YYYY HH:mm',
        FULL_YEAR_TIME_LONG: 'MM/DD/YYYY HH:mm:ss'
    }
}

const getFormat = (f: keyof IFormat): string => {
    const locale = moment().locale()

    let out: IFormat;
    if(override[locale]) {
        out = {
            ...defaultFormat,
            ...override[locale]
        }

        return out[f]
    }

    return defaultFormat[f]
}

export default getFormat