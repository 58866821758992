import { Button, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { LoginUserSmall } from "../../../utils/types/generalTypes";

interface Props {
    setUser: (user: LoginUserSmall | undefined) => void;
}

const LoginPasswordUnsetView = (props: Props) => {
    return (
        <>
            <Col xs={{ span: 24 }} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <p style={{ margin: '0px auto 10px auto', fontSize: 20, fontWeight: 'bold' }}>
                    <FormattedMessage defaultMessage={'Password unset'} />
                </p>
                <p style={{ textAlign: 'center' }}>
                    <FormattedMessage defaultMessage={"You cannot login to Barry. Ask your administrator to set a password for your account and try again when it's done"} />
                </p>
            </Col>
            <Col xs={{ span: 24 }} style={{ display: 'flex' }}>
                <Button
                    style={{ margin: 'auto' }}
                    onClick={() => props.setUser(undefined)}
                >
                    <FormattedMessage defaultMessage={'To login page'} />
                </Button>
            </Col>
        </>
    );
};

export default LoginPasswordUnsetView;