import { Card, Col, Row } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Logo from '../../images/logo/barry_events_full.png';
import { LoginUserSmall } from "../../utils/types/generalTypes";
import LanguageMenu from "../common/fields/languageMenu";
import LoginCodeView from "./views/loginCodeView";
import LoginDefaultView from "./views/loginDefaultView";
import LoginForgotView from "./views/loginForgotView";
import LoginInactiveView from "./views/loginInactiveView";
import LoginMultipleView from "./views/loginMultipleView";
import LoginNormalView from "./views/loginNormalView";
import LoginPasswordUnsetView from "./views/loginPasswordUnsetView";
import LoginPasswordView from "./views/loginPasswordView";

const LoginSMS = () => {

    const { lang } = useParams();

    const [user, setUser] = useState<LoginUserSmall | undefined>(undefined);
    const [users, setUsers] = useState<LoginUserSmall[]>([]);
    const [code, setCode] = useState<string>('');
    const [forgotPassword, setForgotPassword] = useState<boolean>(false);

    console.log("KROK", users.length);

    return (
        <>
            <div className='loginsms-background' />
            <div className='loginsms-container'>
                <Card className='loginsms-card'>
                    <Row gutter={[10, 20]}>
                        <div style={{ position: 'absolute', top: 10, right: 5 }}>
                            <LanguageMenu lang={lang} navigateTo='login' placement='bottomRight' />
                        </div>
                        <Col xs={{ span: 24 }} style={{ display: 'flex', marginTop: 10 }}>
                            <div style={{ margin: 'auto', height: 80 }}>
                                <img src={Logo} className="login-logo" alt="Barry logo" />
                            </div>
                        </Col>

                        {
                            user ?
                                user.active && !user.isPasswordSet && user.firstConnection && !user.isSmsEligible ?
                                    <LoginPasswordUnsetView
                                        setUser={setUser}
                                    />
                                    :
                                    !user.active ?
                                        <LoginInactiveView
                                            setUser={setUser}
                                        />
                                        :
                                        forgotPassword && code === '' ?
                                            <LoginForgotView
                                                user={user}
                                                setForgotPassword={setForgotPassword}
                                                setUser={setUser}
                                                setCode={setCode}
                                            />
                                            :
                                            (forgotPassword && code) || code ?
                                                <LoginPasswordView
                                                    user={user}
                                                    code={code}
                                                />
                                                :
                                                !user.isSmsEligible || !user.firstConnection ?
                                                    <LoginNormalView
                                                        user={user}
                                                        setUser={setUser}
                                                        setForgotPassword={setForgotPassword}
                                                    />
                                                    :
                                                    <LoginCodeView
                                                        user={user}
                                                        setUser={setUser}
                                                        setCode={setCode}
                                                    />
                                :
                                users.length !== 0 ?
                                    <LoginMultipleView
                                        users={users}
                                        setUser={setUser}
                                        setUsers={setUsers}
                                    />
                                    :
                                    <LoginDefaultView
                                        setUser={setUser}
                                        setUsers={setUsers}
                                    />
                        }
                    </Row>
                </Card>
            </div>
        </>
    );
};

export default LoginSMS;