import { StoreAction, UserState } from '../../utils/types/storeTypes';

export const USER_INIT_STATE: UserState = {
    loading: false,
    currentUser: undefined,
    company: undefined,
    eventSelected: undefined,
    loadingEventSelected: false,
};

export const GET_CURRENT_USER_DETAILS_STARTED = Symbol('GET_CURRENT_USER_DETAILS_STARTED');
export const GET_CURRENT_USER_DETAILS_FINISHED = Symbol('GET_CURRENT_USER_DETAILS_FINISHED');
export const CHANGE_CURRENT_COMPANY = Symbol('CHANGE_CURRENT_COMPANY');
export const CHANGE_EVENT_SELECTED = Symbol('CHANGE_EVENT_SELECTED');
export const CHANGE_LOADING_EVENT_SELECTED = Symbol('LOADING_EVENT_SELECTED');
export const TOGGLE_LOADING = Symbol('TOGGLE_LOADING');
export const RESET = Symbol('RESET')


const user = (state: UserState = USER_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case GET_CURRENT_USER_DETAILS_STARTED:
            return {
                ...state,
            }
        case GET_CURRENT_USER_DETAILS_FINISHED:
            return {
                ...state,
                currentUser: action.data,
            }
        case CHANGE_CURRENT_COMPANY:
            return {
                ...state,
                company: action.data,
            }
        case CHANGE_EVENT_SELECTED:
            return {
                ...state,
                eventSelected: action.data,
            }
        case CHANGE_LOADING_EVENT_SELECTED:
            return {
                ...state,
                loadingEventSelected: action.data,
            }
        case TOGGLE_LOADING:
            return {
                ...state,
                loading: action.data,
            }
        case RESET:
            return USER_INIT_STATE;
        default:
            return state;
    }
};

export default user;